import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../notify/notify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string = environment.api.url;


  constructor(private http: HttpClient, public notify: NotifyService) { }

  isLoggedIn() {
    const token = localStorage.getItem('token'); // get token from local storage
    return token;

    // TODO Optimize the code
    //const payload = atob(token.split('.')[1]); // decode payload of token
    //const parsedPayload = JSON.parse(payload); // convert payload into an Object

    //return parsedPayload.exp > Date.now() / 1000; // check if token is expired
  }


  logoutUser(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`
    });

    return this.http.get<User>(this.baseUrl + 'logout', { headers })
       .pipe(
        tap( // Log the result or error
          () => localStorage.removeItem('token'),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }
}
