import { Notification } from 'src/app/models/notification.model';
/* eslint-disable @typescript-eslint/naming-convention */

import { NotifyService } from './../notify/notify.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {  Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user.model';
import { Sender } from 'src/app/models/sender.model';
import { Router } from '@angular/router';
import { Contact } from 'src/app/models/contact.model';
import { TemplateCategory } from 'src/app/models/templateCategory.model';
import { Template } from 'src/app/models/template.model';
import { SmsOut } from 'src/app/models/smsout.model';
import { Company } from 'src/app/models/company.model';
import { Country } from 'src/app/models/country.model';
import { Activity } from 'src/app/models/activity.model';
import { ContactList } from 'src/app/models/contactList.model';
import { Integration } from 'src/app/models/integration.model';
import { Service } from 'src/app/models/service.model';
import { AvailableVln } from 'src/app/models/AvailableVln.model';
import { Vln } from 'src/app/models/vln.model';
import { Transaction } from 'src/app/models/transaction.model';
import { CacheBucket, HttpCacheManager, requestDataChanged, withCache } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl: string = environment.api.url;
  countriesBucket = new CacheBucket();

  constructor(private http: HttpClient, public notify: NotifyService, public router: Router,
    private manager: HttpCacheManager) { }

    invalidateTodos(bucket: any) {
      this.manager.delete(bucket);
    }

  addUser(user: User): Observable<any> {
    console.log('user', user);
    return this.http.post<User>(this.baseUrl + 'register', user)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  loginUser(user: User): Observable<any> {
    console.log('user', user);
    return this.http.post<User>(this.baseUrl + 'login', user)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getUserProfile(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<User>(this.baseUrl + 'userDetail', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getUserNotifications(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Notification>(this.baseUrl + 'notifications/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getTemplateCategory(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<TemplateCategory>(this.baseUrl + 'templates/categories/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getTemplates(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Template>(this.baseUrl + 'templates/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getServices(): Observable<Service> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Service>(this.baseUrl + 'services/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getResume(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'dashboard/resume', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getCompany(): Observable<Company> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Company>(this.baseUrl + 'account/company', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getReports(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'lookup/reports/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  doLookup(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'lookup/scan', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getCountries(): Observable<Country> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Country>(this.baseUrl + 'data/countries', { headers, context: withCache({
      key: 'countries',
      clearCachePredicate: requestDataChanged,
      bucket: this.countriesBucket
    }) } )
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getActivities(): Observable<Activity> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Activity>(this.baseUrl + 'data/activities', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  updateContact(contact: Contact): Observable<Contact> {
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    console.log('contact', contact);
    return this.http.post<Contact>(this.baseUrl + 'contacts/edit', contact, options)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  updatePassword(password: any): Observable<any> {
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    return this.http.post<any>(this.baseUrl + 'account/updatePassword', password, options)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  updateProfile(user: User): Observable<any> {
    console.log('user', user);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    return this.http.post<User>(this.baseUrl + 'account/editData', user, options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  updateCompany(company: Company): Observable<any> {
    console.log('company', company);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    return this.http.post<Company>(this.baseUrl + 'account/company/updateCompany', company, options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  addContact(contact: Contact): Observable<any> {
    console.log('contact', contact);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    return this.http.post<User>(this.baseUrl + 'contacts/add', contact, options)

       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  addCredential(integration: Integration): Observable<Integration> {
    console.log('integration', integration);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };
    return this.http.post<Integration>(this.baseUrl + 'developers/api/add', integration, options)

       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getSmsOutHistory(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<SmsOut>(this.baseUrl + 'smsout/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  markNotificationAsRead(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Notification>(this.baseUrl + 'notifications/markAllAsRead', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  removeSender(id: number): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams({fromString: 'id=' + id})
     };

    return this.http.get<Sender>(this.baseUrl + 'senders/remove', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  removeContact(id: number): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams({fromString: 'id=' + id})
     };

    return this.http.get<Contact>(this.baseUrl + 'contacts/remove', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  removeContactFromList(contact_id: number, list_id: number): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams().set('contact_id', contact_id).set('list_id', list_id)
     };

    return this.http.get<Contact>(this.baseUrl + 'contacts/lists/removeOneContact', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getContactListItem(id: number): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams({fromString: 'id=' + id})
     };

    return this.http.get<Contact>(this.baseUrl + 'contacts/lists/read', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getContactInfo(id: number): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams({fromString: 'id=' + id})
     };

    return this.http.get<Contact>(this.baseUrl + 'contacts/read', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getConversation(message_id: string): Observable<any> {
    const token = localStorage.getItem('token');
    console.log('get conversion', message_id);
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      params: new HttpParams({fromString: 'id=' + message_id})
     };

    return this.http.get<Contact>(this.baseUrl + 'smsin/read', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  paginator(url: string): Observable<any> {
    const token = localStorage.getItem('token');

    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.get<any>(url, options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getSenders(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Sender>(this.baseUrl + 'senders/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getAvailableSenders(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'senders/available', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getScheduledTask(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'scheduledTasks/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getAvailableVln(): Observable<AvailableVln> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<AvailableVln>(this.baseUrl + 'vln/available', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getVln(): Observable<Vln> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Vln>(this.baseUrl + 'vln/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getOtps(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Sender>(this.baseUrl + 'otp/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getCampaign(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Sender>(this.baseUrl + 'smsout/campaign/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getContactList(): Observable<ContactList> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<ContactList>(this.baseUrl + 'contacts/lists/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getCredentials(): Observable<Integration> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Integration>(this.baseUrl + 'developers/api/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getTransactions(): Observable<Transaction> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<Transaction>(this.baseUrl + 'refill/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  getConversations(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });

    return this.http.get<any>(this.baseUrl + 'smsin/all', { headers })
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }

  addSender(sender: Sender): Observable<any> {
    console.log('sender', sender);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.post<Sender>(this.baseUrl + 'senders/add', sender, options)
       .pipe(
          tap( // Log the result or error
            data => this.checkResult(data),
          ),
          catchError((err) => {
            this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
            console.error(err);
            throw err;
          }
       )
      );
  }

  addTransaction(val: any): Observable<any> {
    console.log('val', val);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.post<Sender>(this.baseUrl + 'payments/init', val, options)
       .pipe(
          tap( // Log the result or error
            data => this.checkResult(data),
          ),
          catchError((err) => {
            this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
            console.error(err);
            throw err;
          }
       )
      );
  }

  updateContactList(contactList: ContactList): Observable<any> {
    console.log('contactList', contactList);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.post<ContactList>(this.baseUrl + 'contacts/lists/edit', contactList, options)
       .pipe(
          tap( // Log the result or error
            data => this.checkResult(data),
          ),
          catchError((err) => {
            this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
            console.error(err);
            throw err;
          }
       )
      );
  }

  addContactList(contactList: ContactList): Observable<any> {
    console.log('contactList', contactList);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.post<ContactList>(this.baseUrl + 'contacts/lists/add', contactList, options)
       .pipe(
          tap( // Log the result or error
            data => this.checkResult(data),
          ),
          catchError((err) => {
            this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
            console.error(err);
            throw err;
          }
       )
      );
  }

  editSender(sender: Sender): Observable<any> {
    console.log('sender', sender);
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
     };

    return this.http.post<Sender>(this.baseUrl + 'senders/edit', sender, options)
       .pipe(
          tap( // Log the result or error
            data => this.checkResult(data),
          ),
          catchError((err) => {
            this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
            console.error(err);
            throw err;
          }
       )
      );
  }

  getContacts(limit: number): Observable<any> {
    const token = localStorage.getItem('token');
    const options = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      params: new HttpParams({fromString: 'limit=' + limit})
     };

    return this.http.get<Contact>(this.baseUrl + 'contacts/all', options)
       .pipe(
        tap( // Log the result or error
          data => this.checkResult(data),
        ),
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      );
  }


  /*
  getDiscovers(): Observable<any> {
    return this.http.get<Discover>(this.baseUrl + 'globe/getGlobe', withCache())
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      )
  }

  getDiscover(slug : string): Observable<any> {
    const options = { params: new HttpParams({fromString: "slug=" + slug}) };
    return this.http.get<Discover>(this.baseUrl + 'globe/getGlobe/single', options)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      )
  }

  getPost(slug : string): Observable<any> {
    const options = { params: new HttpParams({fromString: "slug=" + slug}) };
    return this.http.get<Post>(this.baseUrl + 'web/blog/single', options)
       .pipe(
         catchError((err) => {
          this.notify.toast('error', 'Une erreur est survenue', 'Veuillez réessayer');
           console.error(err);
           throw err;
         }
       )
      )
  }
  */

  checkResult(data: any){
    if(data.code === 101){
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
  }
}

