import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthLayoutComponent } from './core/_layouts/auth-layout/auth-layout.component';
import { DefaultLayoutComponent } from './core/_layouts/default-layout/default-layout.component';
import { LoginComponent } from './views/auth/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./views/auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./views/auth/register/register.module').then(m => m.RegisterModule)
      },
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'welcome', loadChildren: () => import('./views/onboard/onboard.module').then(m => m.OnboardModule)
      },
      {
        path: 'tableau-de-bord',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'smsout',
        loadChildren: () => import('./views/smsout/smsout.module').then(m => m.SmsoutModule)
      },
      {
        path: 'smsin',
        loadChildren: () => import('./views/smsin/smsin.module').then(m => m.SmsinModule)
      },
      {
        path: 'otp',
        loadChildren: () => import('./views/otp/otp.module').then(m => m.OtpModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./views/contacts/contact-list/contact-list.module').then(m => m.ContactListModule)
      },
      {
        path: 'refill',
        loadChildren: () => import('./views/refill/refill.module').then(m => m.RefillModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'help',
        loadChildren: () => import('./views/help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'tâches-planifiee',
        loadChildren: () => import('./views/tasks/scheduled-task/scheduled-task.module').then(m => m.ScheduledTaskModule)
      },
      {
        path: 'integration',
        loadChildren: () => import('./views/integration/integration.module').then(m => m.IntegrationModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./views/forms/forms.module').then(m => m.FormsModule)
      },
      {
        path: 'hlr',
        loadChildren: () => import('./views/contacts/hlr/hlr.module').then(m => m.HlrModule)
      },
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
