/* eslint-disable @typescript-eslint/naming-convention */
export class User {
  id: number;
  name: string;
  surname: string;
  company: string;
  email: string;
  phone: string;
  password: string;
  // When user is auth
  avatar?: any;
  parent_id?: number;
  notify_url?: string;
  free_credits?: string;
  currency?: string;
  isOwner?: string;
  status?: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name;
    this.surname = obj.surname;
    this.email = obj.email;
    this.phone = obj.phone;
    this.company = obj.company;
    this.password = obj.password;

    this.avatar = obj.avatar;
    this.parent_id = obj.parent_id;
    this.notify_url = obj.notify_url;
    this.free_credits = obj.free_credits;
    this.currency = obj.currency;
    this.isOwner = obj.isOwner;
    this.status = obj.status;

  }
}
