/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    url : 'https://bee.vavasms.com/api/v1/'
   // url : 'http://localhost:8000/api/v1/'
  },
  cinetpay: {
    apikey: '5579980505863a3f6aabd82.89189525',
    site_id: 659913,
    notify_url: 'https://welulla.com/notify',
    return_url: 'https://welulla.com/notify',
  },
  application: {
    name : 'Vavasms',
    logo: 'assets/brand/logo.png',
    email: 'sales@vavasms.com',
    address: 'Abidjan, Côte d\'Ivoire',
    phone: '+225 07 09 53 82 17'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
