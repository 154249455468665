import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/models/menu.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  menu: Menu[] = [];
  selected = 'home';

  constructor(public router: Router, location: Location) {
      let path = location.path();
      path = path.split('/')[1];
      if(path === 'tableau-de-bord' || path === 't%C3%A2ches-planifiee'){
        this.selected = 'home';
      } if(path === 'groups' || path === 'hlr'){
        this.selected = 'contacts';
      } if(path === 'refill'){
        this.selected = 'user';
      } else {
        this.selected = path;
        console.log(this.selected, path);
      }
  }

  ngOnInit(): void {
    this.menu = [
      {
        name : 'Tableau de bord',
        icon : 'bx-home',
        child : [{
          name: 'Mon activité',
          link: '/tableau-de-bord',
          icon : 'line-chart',
          status: true
        },{
          name : 'Tâches planifiées',
          link : '/tâches-planifiee',
          icon : 'calendar',
          status: true
        },],
        status: true,
        slug: 'home'
      },{
        name : 'Mon compte',
        icon : 'bx-user-circle',
        child : [{
          name: 'Mes informations',
          link: '/user/settings',
          icon : 'briefcase',
          status: true
        },{
          name : 'Transactions',
          link : '/user/transactions',
          icon : 'credit-card-out',
          status: true
        },],
        status: true,
        slug: 'user'
      },{
        name : 'Envoi de SMS',
        icon : 'bx-message-dots',
        child : [{
          name: 'Nouveau',
          link: '/smsout/new-message',
          icon : 'comment',
          status: true
        },{
          name: 'Expéditeurs',
          link: '/smsout/senders',
          icon : 'retweet',
          status: true
        },{
          name: 'Templates',
          link: '/smsout/templates',
          icon : 'thumbnails-big',
          status: true
        },{
          name: 'Mes campagnes',
          link: '/smsout/campaigns',
          icon : 'morph-folder',
          status: true
        },{
          name: 'Historique',
          link: '/smsout/history',
          icon : 'morph-envelope',
          status: true
        }],
        status: true,
        slug: 'smsout'
      },{
        name : 'Numéro virtuel',
        icon : 'bxs-phone',
        child : [{
          name: 'Mes numéros',
          link: '/smsin/services',
          icon : 'phone',
          status: true
        },{
          name: 'Vote SMS',
          link: '/smsin/services',
          icon : 'pie-chart',
          status: false
        },{
          name: 'Boîte de réception',
          link: '/smsin/history',
          icon : 'comments',
          status: true
        }],
        status: true,
        slug: 'smsin'
      },{
        name : 'Formulaire',
        icon : 'bx-box',
        child : [{
          name: 'Nouveau',
          link: '/forms/add',
          icon : 'desktop',
          status: true
        },{
          name: 'Mes formulaire',
          link: '/forms',
          icon : 'desktop',
          status: true
        }],
        status: false,
        slug: 'forms'
      },{
        name : 'Contacts',
        icon : 'bxs-contact',
        child : [{
          name: 'Contacts',
          link: '/contacts',
          icon : 'user',
          status: true
        },{
          name: 'Groupes',
          link: '/groups',
          icon : 'users',
          status: true
        },{
          name: 'Hlr lookup',
          link: '/hlr',
          icon : 'diagram',
          status: true
        }],
        status: true,
        slug: 'contacts'
      },{
        name : 'Code d\'autorisation',
        icon : 'bx-check-shield',
        child : [{
          name: 'OTP envoyés',
          link: '/otp/history',
          icon : 'stopwatch',
          status: true
        },{
          name: 'Configurations',
          link: '/otp/settings',
          icon : 'magic',
          status: false
        }],
        status: true,
        slug: 'otp'
      },{
        name : 'Assistance',
        icon : 'bx-help-circle',
        child : [{
          name: 'Intégration',
          link: '/integration',
          icon : 'wrench',
          status: true
        },{
          name: 'Documentation',
          link: 'https://documenter.getpostman.com/view/17971089/UV5XgH9k',
          openTab: true,
          icon : 'notebook',
          status: true
        },{
          name: 'Aide & Support',
          link: '/otp/settings',
          icon : 'morph-bulb',
          status: true
        }],
        status: true,
        slug: 'help'
      }
    ];
  }

  getItemStatus() {
    return this.menu.filter((item) => item.status === true);
  }

}
