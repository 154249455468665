

<!-- BEGIN: Main Menu-->
<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow expanded" data-scroll-to-active="true">
  <div class="container-fluid h-100 m-0 p-0">
    <div class="row justify-content-center h-100 m-0 p-0">
      <div class="col-3 text-center p-0" id="vSide">
        <a class="text-center" routerLink="/tableau-de-bord">
          <img width="30px" style="margin-top:10px" src="assets/brand/mini.png" />
        </a>
        <div class="d-flex align-items-center mt-1" style="position: absolute;">
          <div class="d-flex flex-column">
            <ul class="nav nav-pills justify-content-center" role="tablist">
              <li class="nav-item" *ngFor="let item of getItemStatus(); let index = index; let isFirst = first" routerLinkActive="active">
                <a class="nav-link" [ngClass]="{ 'active': selected === item.slug }" routerLinkActive="active" id="{{ item.slug }}-tab" data-toggle="tab" href="#{{ item.slug }}" role="tab" aria-selected="true" pTooltip="{{ item.name }}" tooltipPosition="right">
                  <i [ngClass]="'bx align-middle ' + item.icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-9 p-0">
        <div class="menu-fixed menu-light menu-accordion menu-shadow expanded" data-scroll-to-active="true">
          <div class="shadow-bottom"></div>
          <div class="main-menu-content ps ps--active-y">
            <div class="tab-content">
              <div class="tab-pane {{ isFirst ? 'active' : '' }}" *ngFor="let item of getItemStatus(); let index = index; let isFirst = first" id="{{ item.slug }}" role="tabpanel">
                <div style="border-bottom: 2px solid #ececec73;margin: 0px;padding: 10px;">
                  <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                      <h6 class="m-0 p-1 section-title">{{ item.name }}</h6>
                    </li>
                  </ul>
                </div>
                <ul class="navigation navigation-main mt-1" id="main-menu-navigation" data-menu="menu-navigation" data-icon-style="lines">
                  <li *ngFor="let child of item.child" class="nav-item" style="margin-bottom: 3px;" [routerLink]="child.link" routerLinkActive="active">
                    <ng-container *ngIf="child.status">
                      <a [routerLink]="child.link" [target]="child.openTab" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="menu-livicon" [attr.data-icon]="child.icon"></i>
                        <span class="menu-title">{{ child.name }}</span>
                      </a>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Main Menu-->


