import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  data: any;
  template: any;
  resume: any;
  contacts: any;
  contact: any;
  scheduledTask: any;
  senders: any;
  templates: any;
  campaigns: any;
  smsin: any;
  smsout: any;
  otp: any;
  contactlist: any;
  apis: any;
  services: any;
  vln: any;
  conversations: any;
  templateCategory: any;
  availableSenders: any;
  contactlists: any;
  hlrReport: any;
  hlrReports: any;

  constructor() { }

  set(data: any){ this.data = data; }
  get(){ return this.data; }

  setTemplate(val: any){ this.template = val;}
  getTemplate(){ return this.template; }

  setResume(val: any){ this.resume = val; }
  getResume(){ return this.resume; }

  setContacts(val: any){ this.contacts = val; }
  getContacts(){ return this.contacts; }

  setContactList(val: any){ this.contactlist = val; }
  getContactList(){ return this.contactlist; }

  setContactLists(val: any){ this.contactlists = val; }
  getContactLists(){ return this.contactlists; }

  setVln(val: any){ this.vln = val; }
  getVln(){ return this.vln; }

  setConversations(val: any){ this.conversations = val; }
  getConversations(){ return this.conversations; }

  setTemplateCategory(val: any){ this.templateCategory = val; }
  getTemplateCategory(){ return this.templateCategory; }

  setApis(val: any){ this.apis = val; }
  getApis(){ return this.apis; }

  setServices(val: any){ this.services = val; }
  getServices(){ return this.services; }

  setContact(val: any){ this.contact = val; }
  getContact(){ return this.contact; }

  setScheduledTask(val: any){ this.scheduledTask = val; }
  getScheduledTask(){ return this.scheduledTask; }

  setSenders(val: any){ this.senders = val; }
  getSenders(){ return this.senders; }

  setAvailableSenders(val: any){ this.availableSenders = val; }
  getAvailableSenders(){ return this.availableSenders; }

  setTemplates(val: any){ this.templates = val; }
  getTemplates(){ return this.templates; }

  setCampaigns(val: any){ this.campaigns = val; }
  getCampaigns(){ return this.campaigns; }

  setSmsin(val: any){ this.smsin = val; }
  getSmsin(){ return this.smsin; }

  setSmsout(val: any){ this.smsout = val; }
  getSmsout(){ return this.smsout; }

  setOtp(val: any){ this.otp = val; }
  getOtp(){ return this.otp; }

  setHlrReports(val: any){ this.hlrReports = val; }
  getHlrReports(){ return this.hlrReports; }

  setHlrReport(val: any){ this.hlrReport = val; }
  getHlrReport(){ return this.hlrReport; }
}
