    <!-- BEGIN: Header-->
    <div class="header-navbar-shadow"></div>
    <nav class="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top navbar-light">
      <div class="navbar-wrapper">
        <div class="navbar-container content">
          <div class="navbar-collapse" id="navbar-mobile">
            <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
              <ul class="nav navbar-nav">
                <li class="nav-item mobile-menu d-xl-none mr-auto">
                  <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="javascript:void(0);">
                    <i class="ficon bx bx-menu"></i>
                  </a>
                </li>
              </ul>
            </div>
            <ul class="nav navbar-nav float-right">
              <li class="nav-item mt-1 mr-1" *ngIf="resume">
                <a class="btn btn-clear bg-white mr-1">
                  Solde: {{ resume.balance }} XOF
                </a>
                <button class="btn btn-primary" routerLink="/refill">
                  Recharger
                </button>
              </li>
              <li class="dropdown dropdown-language nav-item">
                <a class="dropdown-toggle nav-link" id="dropdown-flag"
                  href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="flag-icon" [ngClass]="selectedLn.flag"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdown-flag">
                  <a class="dropdown-item" href="javascript:void(0);" attr.data-language="{{ item.code }}" *ngFor="let item of languages ; index as i" (click)="chooseLn(i)">
                    <i class="flag-icon mr-50" [ngClass]="item.flag"></i>
                    {{ item.name }}
                  </a>
                </div>
              </li>
              <li class="dropdown dropdown-notification nav-item">
                <a class="nav-link nav-link-label" href="javascript:void(0);" data-toggle="dropdown">
                  <i class="ficon bx bx-bell"></i>
                  <span class="badge badge-pill badge-primary badge-up">{{ this.notifications.length }}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                  <li class="dropdown-menu-header">
                    <div class="dropdown-header px-1 py-75 d-flex justify-content-between">
                      <span class="notification-title">{{ this.notifications.length }} nouvelles notifications</span>
                      <span class="text-bold-400 cursor-pointer" (click)="markAllAsRead()" *ngIf="this.notifications.length > 0">Marquer comme lu</span>
                    </div>
                  </li>
                  <li class="scrollable-container media-list">
                    <a class="d-flex justify-content-between" [href]="item.link" *ngFor="let item of notifications">
                      <div class="media d-flex align-items-center">
                        <div class="media-left pr-0">
                          <div class="avatar mr-1 m-0">
                            <img [src]="item.image" alt="avatar" height="39"
                              width="39">
                            </div>
                        </div>
                        <div class="media-body">
                          <h6 class="media-heading">
                            <span class="text-bold-500">{{ item.title }}</span>
                            {{ item.description }}
                          </h6>
                          <small class="notification-text">{{ item.created_at }}</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown-menu-footer">
                    <a class="dropdown-item p-50 text-primary justify-content-center" href="javascript:void(0)" *ngIf="this.notifications.length > 0">
                      Voir toutes les notifications
                    </a>
                    <a class="dropdown-item p-50 text-primary justify-content-center" href="javascript:void(0)" *ngIf="this.notifications.length <= 0">
                      Aucune notification
                    </a>
                  </li>
                </ul>
              </li>
              <li class="dropdown dropdown-user nav-item">
                <a class="dropdown-toggle nav-link dropdown-user-link" href="javascript:void(0);" data-toggle="dropdown" *ngIf="loading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </a>
                <a class="dropdown-toggle nav-link dropdown-user-link" href="javascript:void(0);" data-toggle="dropdown" *ngIf="!loading">
                  <div class="user-nav d-sm-flex d-none">
                    <span class="user-name">{{ user.name }} {{ user.surname }}</span>
                    <span class="user-status" *ngIf="company">{{ company?.name + ' - Administrateur' }}</span>
                  </div>
                  <span>
                    <img class="round" [src]="user.avatar ? user.avatar : 'assets/brand/user.svg'"
                      alt="avatar" height="40" width="40">
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="javascript:void(0);" routerLink="/user/settings">
                    <i class="bx bx-user-circle mr-50"></i>
                    Mon compte
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="logout()">
                    <i class="bx bx-power-off mr-50"></i> Se déconnecter
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- END: Header-->
