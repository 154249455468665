<app-nav-bar></app-nav-bar>
<app-side-bar></app-side-bar>
<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper mt-5">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="sidenav-overlay" id="sidenav-overlay-2" style="overflow: auto;"></div>
<div class="drag-target"></div>

<app-footer></app-footer>
