/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StoreService } from 'src/app/core/services/store/store.service';
import { Language } from 'src/app/models/language.model';
import { Notification } from 'src/app/models/notification.model';
import { User } from 'src/app/models/user.model';

declare let $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  showUserDropdown = false;
  showNDropdown = false;
  showLDropdown = false;
  toggleMenu = false;

  languages: Language[] = [];
  notifications: Notification[] = [];

  loading = true;
  user = new User();

  selectedLn: any = {
    id: 1,
    code: 'fr',
    name: 'Français',
    flag: 'flag-icon-fr',
    status: true
  };

  company: any;
  resume: any;

  constructor(public api: ApiService, private auth: AuthService, private router: Router,
    private store: StoreService) { }

  ngOnInit(): void {
    this.languages = [{
      id: 1,
      code: 'fr',
      name: 'Français',
      flag: 'flag-icon-fr',
      status: true
    }, {
      id: 2,
      code: 'us',
      name: 'English',
      flag: 'flag-icon-us',
      status: true
    }, {
      id: 3,
      code: 'de',
      name: 'German',
      flag: 'flag-icon-de',
      status: true
    }];

    this.userData();
    this.userNotifications();
    this.stats();
  }

  stats(){
    if(this.store.getResume()){
      this.resume = this.store.getResume();
      this.loading = false;
    } else {
      this.api.getResume()
      .subscribe((res: any) => {
        this.loading = false;
        console.log(res.data);
        this.resume = res.data;
        this.store.setResume(this.resume);
      });
    }
  }

  userData(){
    let d: any = localStorage.getItem('user');
    if(d){
      d = JSON.parse(d);
      this.user = d[0];
      this.company = d[1];
      this.loading = false;
    } else {
      this.api.getUserProfile()
      .subscribe((res: any) => {
        this.user = res.data;

        this.api.getCompany()
        .subscribe((res: any) => {
          this.loading = false;
          this.company = res.data;
          localStorage.setItem('user', JSON.stringify([this.user, this.company]));
        });
      });
    }

  }

  userNotifications(){
    this.api.getUserNotifications()
    .subscribe((res: any) => {
      this.notifications = res.data;
    });
  }

  chooseLn(i: number){
    // TODO Call API
    console.log(this.languages[i]);
    this.selectedLn = this.languages[i];
  }

  markAllAsRead(){
    // TODO Call API
    this.api.markNotificationAsRead()
    .subscribe((res: any) => {
      this.notifications = res.data;
    });
  }

  updateClass(ele: any, cls: any, nn: any) {
    const reg = new RegExp('(\\s|^)'+cls+'(\\s|$)');
    ele.className = ele.className.replace(reg, nn);
  }

  vMenu(){
    console.log(this.toggleMenu);
    const body = document.getElementById('vbody');

    if(this.toggleMenu === false){
      console.log('Open');
      document.getElementById('vbody')?.classList.remove('menu-open');
      document.getElementById('vbody')?.classList.add('menu-hide');
      document.getElementById('vbody')?.classList.add('menu-collapsed');
      console.log(1);
      document.getElementsByClassName('menu-toggle')[0].classList.add('is-active');
      document.getElementById('sidenav-overlay-2')?.classList.remove('d-none');
      document.getElementById('sidenav-overlay-2')?.classList.add('d-block');
      console.log(2);
      if(body){
        body.style.overflow = 'hidden';
      }
      this.toggleMenu = true;
      console.log(3);
    } else {
      console.log('Close');
      //body.removeClass('menu-open').addClass('menu-hide menu-collapsed');

      body?.classList.remove('menu-open');
      body?.classList.add('menu-hide');
      body?.classList.add('menu-collapsed');

      document.getElementsByClassName('menu-toggle')[0].classList.remove('is-active');
      document.getElementById('sidenav-overlay-2')?.classList.remove('d-block');
      document.getElementById('sidenav-overlay-2')?.classList.add('d-none');
      if(body){
        body.style.overflow = 'auto';
      }
      this.toggleMenu = false;
    }
  }

  logout(){
    this.auth.logoutUser()
    .subscribe((res: any) => {
      this.router.navigate(['login']);
      localStorage.clear();
    }, err => {
      localStorage.clear();
      this.router.navigate(['login']);
    });
  }

}
