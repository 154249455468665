import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './core/_layouts/default-layout/default-layout.component';
import { AuthLayoutComponent } from './core/_layouts/auth-layout/auth-layout.component';
import { NavBarComponent } from './core/_layouts/components/nav-bar/nav-bar.component';
import { SideBarComponent } from './core/_layouts/components/side-bar/side-bar.component';
import { FooterComponent } from './core/_layouts/components/footer/footer.component';
import { MobileSideBarComponent } from './core/_layouts/components/mobile-side-bar/mobile-side-bar.component';
import { CircularMenuComponent } from './core/_layouts/components/circular-menu/circular-menu.component';
import { PanelComponent } from './core/_layouts/components/panel/panel.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { environment } from 'src/environments/environment';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { TooltipModule } from 'primeng/tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { HttpCacheInterceptorModule, HTTP_CACHE_CONFIG, useHttpCacheLocalStorage } from '@ngneat/cashew';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    AuthLayoutComponent,
    NavBarComponent,
    SideBarComponent,
    FooterComponent,
    MobileSideBarComponent,
    CircularMenuComponent,
    PanelComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot({
      ttl: 4000,
      strategy: 'implicit'
    }),
    TooltipModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    HighchartsChartModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [useHttpCacheLocalStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }

